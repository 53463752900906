<template>
  <div class="content">
    <app-title>Certificates</app-title>
    <br>
    <ul class="is-unstyled">
      <li v-for="cert in certs" v-bind:key="cert.id" style="text-align: center">
        <div>
          <h4>{{ cert.title }}</h4>
          <img :src="cert.img" alt="" :width="cert.w">
          <div class="is-hidden-desktop">

          </div>
          <div class="date-tags bot-left is-hidden-mobile">
            <span class="date">{{ cert.date }}</span>
            <div class="tags">
              <span v-for="tag in cert.tags" v-bind:key="tag">&nbsp;#{{ tag }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Title from './Title.vue';

export default {
  components: {
    appTitle: Title,
  },
  data() {
    return {
      certs: [
        {
          id: 'UC-FV9TXSUK',
          title: 'Advanced Javascript',
          date: '2018-05-14',
          img: '/static/img/certs/UC-FV9TXSUK.jpg',
          tags: ['Javascript'],
          w: 450,
        },
        {
          id: 'UC-P9DTAJJZ',
          title: 'AWS CloudFormation Master Class',
          date: '2018-02-11',
          img: '/static/img/certs/UC-P9DTAJJZ.jpg',
          tags: ['AWS', 'CloudFormation'],
          w: 450,
        },
        {
          id: 'UC-USUWDJR2',
          title: 'AWS Lambda and the Serverless Framework',
          date: '2018-02-10',
          img: '/static/img/certs/UC-USUWDJR2.jpg',
          tags: ['AWS', 'Lambda', 'Serverless'],
          w: 450,
        },
        {
          id: 'CB030',
          title: 'Essentials of Couchbase Engagement Database',
          date: '2018-02-05',
          img: '/static/img/certs/CB030.png',
          tags: ['couchbase', 'database', 'nosql'],
          w: 600,
        },
        {
          id: 'CB020',
          title: 'Fundamentals of Engagement Data Management',
          date: '2018-02-05',
          img: '/static/img/certs/CB020.png',
          tags: ['couchbase', 'database', 'nosql'],
          w: 600,
        },
        {
          id: 'UC-6OOTUJSB',
          title: 'Continuous Delivery on Amazon Web Services',
          date: '2018-02-01',
          img: '/static/img/certs/UC-6OOTUJSB.jpg',
          tags: ['aws', 'CD/CI'],
          w: 450,
        },
        {
          id: 'UC-C9MMX4NX',
          title: 'AWS Serverless APIs & Apps',
          date: '2018-01-30',
          img: '/static/img/certs/UC-C9MMX4NX.jpg',
          tags: ['aws', 'CI/CD'],
          w: 450,
        },
        {
          id: 'UC-4DSLHSBJ',
          title: 'Application security on AWS with Amazon Cognito',
          date: '2018-01-29',
          img: '/static/img/certs/UC-4DSLHSBJ.jpg',
          tags: ['aws', 'cognito', 'security', 'api', 'gateway'],
          w: 450,
        },
        {
          id: 'UC-OBJHB653',
          title: 'Nginx Fundamentals: High Performance Servers from Scratch',
          date: '2018-01-26',
          img: '/static/img/certs/UC-OBJHB653.jpg',
          tags: ['nginx', 'proxy', 'loadbalancer', 'http', 'ssl'],
          w: 450,
        },
        {
          id: 'UC-1SP66MJX',
          title: 'Web Development with Google\'s Go (Golang)',
          date: '2017-12-27',
          img: '/static/img/certs/UC-1SP66MJX.jpg',
          tags: ['golang', 'google', 'go', 'web', 'native'],
          w: 450,
        },
        {
          id: 'UC-FGO93VVR',
          title: 'Docker Mastery: The Complete Toolset from a Docker Captain',
          date: '2017-12-10',
          img: '/static/img/certs/UC-FGO93VVR.jpg',
          tags: ['docker', 'compose', 'container', 'swarm'],
          w: 450,
        },
        {
          id: 'UC-P7F7J0AD',
          title: 'Go: Concurrency & Design Patterns for Gophers',
          date: '2017-12-04',
          img: '/static/img/certs/UC-P7F7J0AD.jpg',
          tags: ['golang', 'go', 'concurrency', 'patterns'],
          w: 450,
        },
        {
          id: 'UC-5QKVFFF5',
          title: 'InfluxDB - Time Series Database...',
          date: '2017-11-01',
          img: '/static/img/certs/UC-5QKVFFF5.jpg',
          tags: ['influxdb', 'database', 'timeseries', 'nosql'],
          w: 450,
        },
        {
          id: 'UC-2TDJCVK6',
          title: 'Bulletproof HTML5 Static Websites with AWS in a Nutshell',
          date: '2017-10-12',
          img: '/static/img/certs/UC-2TDJCVK6.jpg',
          tags: ['aws', 's3', 'html5', 'spa', 'ssl', 'cloudfront'],
          w: 450,
        },
        {
          id: 'UC-NMWSLQ5H',
          title: 'Go Projects',
          date: '2017-07-16',
          img: '/static/img/certs/UC-NMWSLQ5H.jpg',
          tags: ['golang', 'google', 'go', 'projects'],
          w: 450,
        },
        {
          id: 'UC-G95727IA',
          title: 'Getting Started with Cloud Native Go',
          date: '2017-07-15',
          img: '/static/img/certs/UC-G95727IA.jpg',
          tags: ['golang', 'google', 'go', 'cloud'],
          w: 450,
        },
        {
          id: 'UC-AAYWLLO6',
          title: 'Learn How To Code: Google\'s Go (golang)',
          date: '2017-07-15',
          img: '/static/img/certs/UC-AAYWLLO6.jpg',
          tags: ['golang', 'google', 'go'],
          w: 450,
        },
        {
          id: 'UC-S4RN58HK',
          title: 'Docker Compose in Depth',
          date: '2017-06-17',
          img: '/static/img/certs/UC-S4RN58HK.jpg',
          tags: ['docker', 'compose', 'container'],
          w: 450,
        },
        {
          id: 'UC-8U7V78TE',
          title: 'Docker for Developers v1.12',
          date: '2017-06-17',
          img: '/static/img/certs/UC-8U7V78TE.jpg',
          tags: ['docker', 'compose', 'swarm'],
          w: 450,
        },
        {
          id: 'UC-Z0NI69G0',
          title: 'Persistence with mongoDB in Go(golang)',
          date: '2017-05-25',
          img: '/static/img/certs/UC-Z0NI69G0.jpg',
          tags: ['go', 'database', 'nosql', 'mongodb'],
          w: 450,
        },
        {
          id: 'UC-BQ23V1C4',
          title: 'Serverless Architecture on Amazon Web Services',
          date: '2017-04-25',
          img: '/static/img/certs/UC-BQ23V1C4.jpg',
          tags: ['aws', 'serverless', 's3', 'dynamodb', 'lambda'],
          w: 450,
        },
      ],
    };
  },
};
</script>

<style scoped>
.content {
  padding: 0 20px;
}

img {
  margin-bottom: -200px;
}

h4 {
  font-weight: bold;
}

li {
  margin-bottom: 40px;
}

li div {
  margin: 0 auto;
  max-width: 800px;
}

.date-tags {
  max-width: 600px;
  text-align: left;
  padding: 220px 20px 10px;

}

.date {
  font-weight: bold;
}

.tags {
  float: right;
  text-transform: uppercase;
}

.bot-left {
  position: relative;
}

.bot-left:before, .bot-left:after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: -3px;

}

.bot-left:before {
  top: -3px;
  width: 3px;
  background: -moz-linear-gradient(top, rgba(252, 252, 252, 0) 0%,
  rgba(208, 10, 0, 0.66) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(252, 252, 252, 0) 0%,
  rgba(208, 10, 0, 0.66) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(252, 252, 252, 0) 0%,
  rgba(208, 10, 0, 0.66) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00fcfcfc',
  endColorstr='#a8d00a00', GradientType=0); /* IE6-9 */
}

.bot-left:after {
  right: -3px;
  height: 3px;

  /*
  Permalink - use to edit and share this gradient:
  http://colorzilla.com/gradient-editor/#fcfcfc+0,d00a00+100&0+0,0.66+100
  */
  background: -moz-linear-gradient(left, rgba(208, 10, 0, 0.66) 0%,
  rgba(252, 252, 252, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(208, 10, 0, 0.66) 0%,
  rgba(252, 252, 252, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(208, 10, 0, 0.66) 0%,
  rgba(252, 252, 252, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a8d00a00',
  endColorstr='#00fcfcfc', GradientType=1); /* IE6-9 */
}

@media screen and (max-width: 1023px) {
  img {
    margin-bottom: 10px;
  }
}
</style>
