<template>
  <div id="app" class="container">
    <app-contacts/>
    <router-view/>
    <app-footer/>
  </div>
</template>

<script>
import Contacts from './components/Contacts.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    appContacts: Contacts,
    appFooter: Footer,
  },
  name: 'App',
};
</script>
<style lang="scss">
  @import './assets/main.scss';
</style>

<style>
  .h-line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ghostwhite;
    margin-bottom: 10px;
  }
</style>
