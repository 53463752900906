<template>
  <div class="contacts is-centered">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/static/img/z-logo-red.png" alt="" height="39" width="59">
        </a>
        <button v-on:click="activate_menu()" class="button navbar-burger">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <div class="navbar-menu" v-bind:class="{ 'is-active': isActive }">
        <div class="navbar-start">
          <a class="navbar-item is-hidden-desktop" href="/">
            <span class="icon is-small"><i class="fas fa-home"></i></span>
            <span> Home</span>
          </a>

          <a class="navbar-item" href="https://www.linkedin.com/in/zoltan-humpok/" target="_blank">
            <span class="icon is-small"><i class="fab fa-linkedin"></i></span>
            <span> LinkedIn</span>
          </a>

          <a class="navbar-item" href="https://www.facebook.com/humpokzoltan" target="_blank">
            <span class="icon is-small"><i class="fab fa-facebook"></i></span>
            <span>Facebook</span>
          </a>

          <a class="navbar-item" href="#">
            <span>info</span>
            <span class="icon is-small at-sign"><i class="fas fa-at"></i></span>
            <span>zoltan.pro</span>
          </a>
        </div>

        <div class="navbar-end is-hidden-desktop">
          <div class="navbar-item">
            <a href="/certificates">
              <span class="icon">
                <i class="fas fa-certificate"></i>
              </span>
              <span>Certificates</span>
            </a>
          </div>
          <div class="navbar-item">
            <a href="/static/zoltan_humpok_cv_2020.pdf">
              <span class="icon">
                <i class="fas fa-download"></i>
              </span>
              <span>CV Download</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    activate_menu() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
  .contacts {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #111c23;
    margin: 10px auto;
  }
  .navbar {
    border-bottom: #dbdbdb solid 1px;
    margin: 0 20px;
  }
  a.navbar-item {
    border-bottom: transparent solid 1px;
  }
  a.navbar-item:hover,
  a.navbar-item.is-active,
  a.navbar-link:hover,
  a.navbar-link.is-active {
    color: #111c23;
    background: none;
    border-bottom: #111c23 solid 1px;
  }

  .at-sign {
    display: inline-block;
    margin: 0 5px 12px 0 !important;
  }

  @media screen and (max-width: 1023px) {
    .contacts {
      margin: 0 auto 20px;
    }
    a.navbar-item {
      border-bottom: none;
    }
    .navbar-menu.is-active {
      text-align: center;
    }
    a.navbar-item:hover,
    a.navbar-item.is-active,
    a.navbar-link:hover,
    a.navbar-link.is-active {
      border-bottom: none;
    }
  }

  a {
    font-size: 20px;
    padding: 0.5em 0.3em;
    text-decoration: none;
    color: #111c23;
  }
  .at-sign {
    display: inline-block;
  }

  .icon {
    margin: 10px
  }

</style>
