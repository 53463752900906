import { createStore } from 'vuex';

export default createStore({
  state: {
    skills: [
      'Blockchain',
      'C, C++',
      'CockroachDB',
      'Docker',
      'Forex',
      'Golang',
      'GitLab',
      'Java',
      'Javascript',
      'JIRA',
      'MongoDB',
      'MySQL',
      'Ruby on Rails',
      'ReactJS',
      'VueJS',
      'Terraform',
      'Project Management',
      'Linux',
      'Unix',
      'TDD',
      'CQRS',
      'Microservices',
      'Code Quality',
      'Sonar',
      'ELK stack',
      'Hashi stack',
      'GRPC',
      'ARM',
      'Ubuntu',
      'DevOps',
      'Domain Driven Development',
      'K6',
      'Grafana',
      'Vault',
      'CI/CD',
      'Nginx',
      'Hetzner Cloud',
      'Scaleway',
      'M1',
    ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
