<template>
  <div id="footer">
    <div class="h-line"></div>
    <div class="footer-content"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
  .footer-content {
    text-align: center;
    padding-bottom: 40px;
  }
  #footer {
    margin-bottom: 40px;
  }
</style>
