<template>
  <div class="wrapper">
  <div class="styled-title">
    <div class="title-container">
      <span><slot>Title</slot></span><!-- noting here  --><span class="end">&nbsp;</span>
    </div>
    <div class="bottom-line2"></div>
  </div>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
  div.styled-title {
    display: inline-block;
    div.title-container {
      padding-right: 60px;
      span {
        display: inline-block;
        padding-left: 0.5em;
        padding-right: 60px;
        background-color: #d00a00;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfcfc+0,ce3e39+69,d00a00+100&0+0,1+100 */
        background: -moz-linear-gradient(left, rgba(252,252,252,0) 0%,
          rgba(206,62,57,0.69) 69%, rgba(208,10,0,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(252,252,252,0) 0%,
          rgba(206,62,57,0.69) 69%,rgba(208,10,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(252,252,252,0) 0%,
          rgba(206,62,57,0.69) 69%,rgba(208,10,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfcfc',
          endColorstr='#d00a00',GradientType=1 ); /* IE6-9 */

        font-size: 1.4em;
        font-weight: normal;
        text-align: left;
        margin-bottom: 0;
      }
      span.end {
        display: inline-block;
        width: 2.0em;
        background-image: url("/static/img/redtriangle.png");
      }
    }
    .bottom-line2 {
      height: 3px;
      border-bottom: 3px solid black;
    }
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: 1023px) {
    div.styled-title {
      div.title-container {
        padding-right: 0;
      }
    }
  }

</style>
