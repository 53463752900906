<template>
  <div class="content">
    <div class="content-body">
      <div class="columns">
        <div class="column is-two-thirds">
          <app-title>About Me</app-title>
          <div class="columns">
            <div class="column is-one-third has-text-centered">
              <img class="profile-pix"
                   src="/static/img/profile.jpg"
                   alt="" width="200" height="200">
              <div class="links">
                <ul class="is-unstyled">
                  <li>
                    <a href="/static/zoltan_humpok_cv_2024.pdf">
                      <i class="fas fa-long-arrow-alt-right"></i> Detailed CV
                    </a>
                  </li>
                  <li>
                    <a href="/certificates">
                      <i class="fas fa-long-arrow-alt-right"></i> Certificates
                    </a>
                  </li>
                </ul>
                <p class="has-text-centered">
                  <a href="/static/AWS-CSAA.pdf">
                    <img src="/static/img/aws-certified-logo_color-horz_270x45.png" alt="">
                  </a>
                </p>
                <p class="has-text-centered">
                  <a href="https://education.ardanlabs.com/certificates/ukrpirsger" target="_blank">
                    <img style="width: 160px" src="/static/img/ardanlabs-logo.svg" alt="">
                  </a>
                </p>
              </div>
            </div>

            <div class="column">
              <div class="short-resume">
                <p>My name is Zoltán Humpok.</p>
                <p>I'm a full-stack developer,</p>
                <p>I live in Budapest, Hungary.
                  I was born on August 15, 1981 in a small town called Marcali.</p>
                <p>I really love to use the following technologies:
                  Golang, Javascript, GitLab, Ruby on Rails, VueJS, CockroachDB, MongoDB,
                  AWS lambda, Java, Terraform, Consul, Nomad, Kubernetes...</p>
                <p>&nbsp;</p>
                <p>Working on my own pet projects. Like a HA cloud agnostic infrastructure
                  without the usual complexity. Or managing my servers... Ask for details :)</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column xp-list">
          <app-title>Xp</app-title>
          <div>
            <span v-for="(skill, idx) in $store.state.skills.sort()" :key="idx">
              {{skill}}<i class="fas fa-star"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from './Title.vue';

export default {
  components: {
    appTitle: Title,
  },
};
</script>

<style lang="scss" scoped>
#app {
  max-width: 1024px;
  margin: 0 auto;
}
.profile-pix {
  margin-top: 20px;
}

.xp-list {
  border-left: 1px solid ghostwhite;

  ul {
    margin-top: 20px;
  }
}

.xp-list li span {
  display: inline-block;
  float: right;
}

a {
  text-decoration: none;
  color: black;
}

.links {
  margin: 20px auto 0;
  max-width: 200px;

  li {
    width: 100%;
    display: block;
    text-align: left;
    padding-left: 20px;
    line-height: initial;
  }
}

.short-resume {
  margin: 20px;
}
.svg-inline--fa {
  margin: 0 2px;
}
</style>
